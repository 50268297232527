import React, {useMemo} from 'react';
import {Link, NavLink} from 'react-router-dom'
import style from './style.module.scss'
import {ACTIVITY, TRENDS} from '../../components/LeftMenu/const'

const nav = [
  {
    to: '/#0',
    name: '首页'
  },
  {
    to: '/libraryOverview/LibraryIntroduction',
    name: '概况',
    children: [
      {
        to: '/libraryOverview/LibraryIntroduction',
        name: '图书馆介绍'
      },
      {
        to: '/libraryOverview/RoomIntro',
        name: '馆室介绍'
      },
      {
        to: '/libraryOverview/LibraryLayout',
        name: '馆藏布局'
      },
      {
        to: '/libraryOverview/Regulation',
        name: '规章制度'
      },
    ]
  },
  {
    to: '/readerGuide/EntranceRules',
    name: '读者指南',
    children: [
      {
        to: '/readerGuide/EntranceRules',
        name: '入馆须知'
      },
      {
        to: '/readerGuide/ReadersConsulting',
        name: '读者咨询'
      },
    ]
  },
  {
    to: {pathname: 'http://library.ouchn.edu.cn/'},
    name: '国开数字图书',
    target: '_blank'
  },
  {
    to: '/readingActivity/Recommendation',
    name: '阅读活动推广',
    children: [
      {
        to: '/readingActivity/Recommendation',
        name: '每月好书推荐'
      },
      {
        to: `/readingActivity/${TRENDS}`,
        name: '图书馆动态'
      },
      {
        to: `/readingActivity/${ACTIVITY}`,
        name: '活动预告'
      },
    ]
  },
  {
    to: {pathname: '/login'},
    name: '登录',
    target: '_blank'
  },
];

function Header(props) {
  const theme = useMemo(() => {
    if (props.theme) {
      return `theme-${props.theme}`
    }

    if (props.bannerType) {
      return 'theme-1'
    }

    return 'theme-2' // 默认主题（棕色系）
  }, [props.theme, props.bannerType])

  const wrapClassName = `
    ${style['header__wrap']}
    ${style[theme]}
    ${props.bannerType ? style[`header__wrap--banner-${props.bannerType}`] : ''}
  `

  return (
    <div style={props.style} className={wrapClassName}>
      <div className={style['header']}>
        <div className={style['nav__wrap']}>
          <h1 className={style['logo']}><a href="/">西安开放大学图书馆</a></h1>
          <div className={style['nav']}>
            {nav.map((item1, index1) => {
              const {name: name1, children: children1, ...props1} = item1
              return (
                <div className={style['nav__item']} key={index1}>
                  <NavLink
                    {...props1}
                    activeClassName={style['active']}
                    isActive={(match, location) => {
                      // 第一级目录匹配
                      const reg = /\/[^/#]*/ // 不含hash部分
                      const m1 = location.pathname.match(reg)
                      const m2 = props1.to.toLocaleString().match(reg)
                      if (!m1 || !m2) {
                        return false;
                      }
                      return m2[0]?.toLocaleLowerCase() === m1[0]?.toLocaleLowerCase()
                    }}
                  >
                    {name1}
                  </NavLink>
                  {!!children1?.length && (
                    <div className={style['nav__second']}>
                      {children1.map((item2, index2) => {
                        const {name: name2, ...props2} = item2
                        return <Link {...props2} key={index2}>{name2}</Link>
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {props.banner && (
        <div className={style['banner']}>
          {props.banner}
        </div>
      )}
    </div>
  )
}

export default Header
