import React from 'react'


export default class Image extends React.Component {
  static propTypes = {}

  errorCount = 0 // onError事情触发次数计数
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true, // img元素是否可见
      src: this.props.src
    }
  }

  handleError = e => {
    if (this.errorCount > 0) {
      // 第二次触发onError
      this.props.hideErrorImg && this.setState({isVisible: false}) // 默认图片出错的处理方案：隐藏img元素
      return
    }
    e.target.src = this.props.defaultSrc // 第一次触发onError事件，显示默认图片
    this.errorCount++
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.errorCount = 0
      this.setState({
        src: this.props.src
      })
    }
  }

  render() {
    if (!this.state.isVisible) {
      return null
    }
    const {alt, className} = this.props
    const {src} = this.state
    const newSrc = src || this.props.defaultSrc // src 的值为 undefined 和 null 时，不会触发 onError 事件
    return (
      <img
        className={`${className || ''}`}
        src={newSrc}
        alt={alt}
        onError={this.handleError}
      />
    )
  }
}
