import React, {useCallback} from 'react'
import RecommendationList from './RecommendationList'
import BreadcrumbWithSearch from '../../components/BreadcrumbWithSearch'
import style from './index.module.scss'
import {
  ACTIVITY,
  READING_ACTIVITY_MENUS,
  READING_ACTIVITY_TITLE_DICT,
  RECOMMENDATION, TRENDS
} from '../../components/LeftMenu/const'
import LeftMenu from '../../components/LeftMenu'
import Trends from '../Trends'
import List from 'components/List'
import {getForecast} from 'api/request'

export {default as RecommendationDetail} from './components/RecommendationDetail'


export function ReadingActivity(props) {
  const {match: {params: {type = ''}}, history} = props
  const handleRegulationClick = useCallback((id) => {
    history.push(`/readingActivity/${ACTIVITY}/${id}`)
  }, [history])

  return (
    <div className={style.container} style={{marginBottom: type === RECOMMENDATION ? 0 : -16}}>
      <BreadcrumbWithSearch breadcrumb={[
        {name: '阅读活动推广', href: '/readingActivity/Recommendation'},
        {name: READING_ACTIVITY_TITLE_DICT[type]}
      ]}/>
      <LeftMenu activeKey={type} menus={
        READING_ACTIVITY_MENUS.map((menu) => {
          return {
            ...menu,
            handleClick: () => {
              history.push(`/readingActivity/${menu.key}`)
            }
          }
        })}/>
      <div className={style.main}>
        {type === RECOMMENDATION && <RecommendationList />}
        {type === TRENDS && <Trends />}
        {type === ACTIVITY && <List showCover={false} getListFn={getForecast} onClick={handleRegulationClick} />}
      </div>
    </div>
  )
}
