import {useEffect, useState} from 'react'

const usePageData = (getListFn, defaultValue) => {
  const {defaultCurrent = 1, defaultPageSize = 12} = defaultValue || {}
  const [current, setCurrent] = useState(defaultCurrent)
  const [isLoading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  useEffect(() => {
    setLoading(true)
    getListFn(current - 1, pageSize).then(
      (res) => {
        setLoading(false)
        const {total, data} = res || {}
        if ((current - 1) * pageSize > total) {
          setCurrent(1)
        } else {
          setTotal(total)
          setData(data)
        }
      }
    )
  }, [current, getListFn, pageSize])

  return {current, total, data, setCurrent, pageSize, setPageSize, isLoading}
}

export default usePageData
