import React, {useState, useEffect, useCallback} from 'react'
import {Image} from "antd";
import {withRouter} from 'react-router'
import Button from 'components/Button'
import style from './index.module.scss'
import useSize, { getSize } from '../../../../useSize'
import { groupBy } from 'lodash-es'
import { BASE_URL } from 'api/config'
import {placeholder} from 'consts/imgConst'
import Empty from 'components/Empty'

function Recommend(props) {
  const { data } = props
  const [showNum, setShowNum] = useState(0)

  const resize = (contentHeight) => {
    let doms = []
    let rowHeight = document.querySelector(`[class^="Recommend_recommend-item"]`) ? document.querySelector(`[class^="Recommend_recommend-item"]`).offsetHeight + 20 : 0 // 每行元素高度
    const contentMaxHeight = contentHeight - (document.querySelector(`[class^="Title_container"]`) ? document.querySelector(`[class^="Title_container"]`).offsetHeight : 0) - (document.querySelector(`[class^="Recommend_recommend-footer"]`) ? document.querySelector(`[class^="Recommend_recommend-footer"]`).offsetHeight : 0) - (document.querySelector(`[class^="ReadingActivitiesPromotion_tabs"]`) ? document.querySelector(`[class^="ReadingActivitiesPromotion_tabs"]`).offsetHeight : 0)
    doms = Array.prototype.slice.call(document.querySelectorAll(`[class^="Recommend_recommend-item"]`))
    const group = groupBy(doms, (item) => {
      return item.getBoundingClientRect().top
    })
    let rowKeys = Object.keys(group)
    let columns = rowKeys.length ? group[rowKeys[0]].length : 0
    let maxRows = Math.floor(contentMaxHeight / rowHeight)
    setShowNum(maxRows * columns)
  }

  useEffect(() => {
    const {contentHeight} = getSize()
    resize(contentHeight)
  }, [data])

  useSize(({contentHeight}) => {
    resize(contentHeight)
  })

  const handleMore = useCallback(() => {
    props.history.push('/readingActivity/Recommendation')
  }, [props.history])

  const handleClick = useCallback((id) => {
    props.history.push(`/readingActivity/Recommendation/${id}`)
  }, [props.history])

  return (
    <div className={style['recommend-wrapper']} style={props.style}>
      <div className={style['recommend-container']}>
        {!!data && (
          !data.length
            ? <Empty icon={'list'} tip={'暂无内容'} minHeight={300} />
            : (
              data.map((item, index) => {
                return (
                  <div
                    onClick={() => handleClick(item.id)}
                    key={item.id}
                    className={style['recommend-item']}
                    style={index >= showNum ? {visibility: 'hidden'} : {visibility: 'unset'}}
                  >
                    <div className={style['recommend-cover']}>
                      <Image preview={false} src={`${BASE_URL}${item.cover_image}`} fallback={placeholder} />
                    </div>
                    <div className={style['recommend-title']} title={item.title}>
                      {item.title}
                    </div>
                    <div className={style['recommend-author']} title={item.author}>
                      作者：{item.author}
                    </div>
                  </div>)
              })
            )
        )}
      </div>
      <div className={style['recommend-footer']}>
        <Button onClick={handleMore}>查看更多</Button>
      </div>
    </div>
  )
}

export default withRouter(Recommend)
