export const INFO_TYPE = {
  whole: 'whole',
  notice: 'notice',
  dynamic: 'dynamic',
  forecast: 'forecast'
}
export const INFO_TYPE_DICT = {
  '122238737590517760': 'notice',
  '122239245597278208': 'dynamic',
  '122239268851548160': 'forecast'
}

export const BOOK_TYPE = {
  name: 'name',
  author: 'author',
}

export const KEYWORD_MAX_LEN = 20
