import React, {useEffect, useState} from 'react'
import Title from 'components/Title';
import style from './index.module.scss'
import {LeftOutlined, RightOutlined} from '@ant-design/icons'
import useSize from '../../useSize'
import {getDigitalReading} from '../../../../api/request'
import {BASE_URL} from '../../../../api/config'
import Image from '../../../../components/Image'
import defaultImg from './images/book-bg.png'


const PAGE_SIZE_DICT = {
  'small': 6,
  'normal': 10
}

function DigitalReadingRoom() {
  const [currentPage, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const {type} = useSize()
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === (Math.floor(total / PAGE_SIZE_DICT[type]) + 1)
  const [data, setData] = useState([])
  useEffect(() => {
    getDigitalReading(currentPage - 1, PAGE_SIZE_DICT[type]).then(res => {
      const {data: {data_list = [], total_elements = 0} = {}} = res
      setTotal(total_elements)
      setData(data_list.map((item) => {
        const {title, link, cover_image} = item
        return {link, title, src: `${BASE_URL}${cover_image}`}
      }))
    })
  }, [currentPage, type])
  useEffect(() => {
    setPage(1)
  }, [type])
  return (
    <div className={style.container} style={{width: `${1300 - (type === 'small' ? 450 : 0)}px`}}>
      <Title mainText={'电子阅览室'} tipText={'DIGITAL READING ROOM'} size={'small'} />
      <div className={style.gridList}>
        {!isFirstPage &&
        <div className={style.leftBtn} onClick={() => setPage(currentPage - 1)}><LeftOutlined style={{color: '#fff'}}/>
        </div>}
        {(data || []).slice(0, PAGE_SIZE_DICT[type]).map((item) => {
          const {link, title, src} = item
          return <div
            key={title}
            className={style.gridItem}
            onClick={() => {
              window.open(link)
            }}
            style={{width: `${200 / PAGE_SIZE_DICT[type]}%`}}
          >
            <Image src={src} defaultSrc={defaultImg}/>
            <div className={style.bookName} title={title}>{title}</div>
          </div>
        })}
        {!isLastPage &&
        <div className={style.rightBtn} onClick={() => setPage(currentPage + 1)}><RightOutlined
          style={{color: '#fff'}}/></div>}
      </div>
    </div>
  )
}

export default DigitalReadingRoom
