import React, {useCallback} from 'react'
import style from './style.module.scss'
import {LIBRARY_OVERVIEW_DICT, LIBRARY_OVERVIEW_MENUS, REGULATION} from '../../components/LeftMenu/const'
import LeftMenu from '../../components/LeftMenu'
import EditorShow from '../../components/EditorShow'
import useHtmlContent from '../../hook/useHtmlContent'
import BreadcrumbWithSearch from '../../components/BreadcrumbWithSearch'
import {Skeleton} from 'antd'
import List from 'components/List'
import {getRegulation} from 'api/request'

const Main = ({type}) => {
  const [data, isLoading] = useHtmlContent(LIBRARY_OVERVIEW_DICT[type])
  const {title_type, content_type, html, plain_text, link} = data || {}

  return (
    <div className={style.detail}>
      {
        !isLoading
          ? <EditorShow {...{title_type, content_type, html, plain_text, link}} />
          : <><Skeleton active/><Skeleton active/><Skeleton active/><Skeleton active/></>
      }
    </div>
  )
}

function LibraryOverview(props) {
  const {match: {params: {type = ''}}, history} = props

  const handleRegulationClick = useCallback((id) => {
    history.push(`/libraryOverview/${REGULATION}/${id}`)
  }, [history])

  return (
    <div className={style.container}>
      <BreadcrumbWithSearch
        breadcrumb={[
          {name: '图书馆概况', href: '/libraryOverview/LibraryIntroduction'},
          {name: LIBRARY_OVERVIEW_MENUS.find(({key}) => type === key).name}
        ]}
      />
      <LeftMenu activeKey={type} menus={
        LIBRARY_OVERVIEW_MENUS.map((menu) => {
          return {
            ...menu,
            handleClick: () => {
              history.push(`/libraryOverview/${menu.key}`)
            }
          }
        })}
      />

      <div className={style.main}>
        {type === REGULATION
          ? <List showCover={false} showBrief={false} showDate={false} getListFn={getRegulation} onClick={handleRegulationClick} />
          : <Main type={type} />
        }
      </div>
    </div>
  )
}

export default LibraryOverview
