import React, {useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import queryString from 'query-string'
import style from './style.module.scss'
import Book from './component/Book'
import Info from './component/Info'
import BreadcrumbWithSearch from "components/BreadcrumbWithSearch";
import {BOOK_TYPE, INFO_TYPE} from "consts/common";

const getOptions = (option, type) => {
  const allTypeKeys = Object.keys(type === 'book'  ? BOOK_TYPE : INFO_TYPE)
  return allTypeKeys.find((key) => key === option) ? option : allTypeKeys[0]
}

function SearchResult(props) {
  const history = useHistory()

  const defaultKeyword = useMemo(() => {
    return queryString.parse(props.location.search).keyword.trim()
  }, [props.location.search]);

  const defaultOption = useMemo(() => {
    return queryString.parse(props.location.search).option
  }, [props.location.search]);

  const type = useMemo(() => {
    return props.match.params.type
  }, [props.match.params.type]);

  const [refresh, setRefresh] = useState(Math.random());
  const handleSearch = useCallback(({value, option, selected}) => {
    const keyword = value?.trim()
    history.replace({
      pathname: `/searchResult/${selected}`,
      search: `keyword=${encodeURIComponent(keyword)}&option=${getOptions(option, selected)}`
    })
    setRefresh(Math.random());
  }, [history]);

  const handleChangeType = useCallback((option) => {
    history.replace({
      search: `keyword=${encodeURIComponent(defaultKeyword)}&option=${getOptions(option, type)}`
    })
    setRefresh(Math.random());
  }, [defaultKeyword, type, history]);

  return (
    <div className={style['searchResult']}>
      <BreadcrumbWithSearch
        breadcrumb={[{name: `查询“${defaultKeyword}”的结果`}]}
        searchProps={{
          onChange: handleSearch,
          selectTriggerTypeChange: true,
          type,
          value: defaultKeyword,
          onSearch: handleSearch
        }}
      />
      {type === 'book'
        ? <Book keyword={defaultKeyword} refresh={refresh} />
        : <Info keyword={defaultKeyword} classify={defaultOption} handleChangeType={handleChangeType} refresh={refresh} />
      }
    </div>
  )
}

export default SearchResult
