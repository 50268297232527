import React from 'react'
import style from './index.module.scss'

/**
 *
 * @param props.size 'small', 'middle'(默认值)
 * @returns {JSX.Element}
 * @constructor
 */
function Title(props) {
  const {mainText, tipText, tipStyle = {}, mainStyle = {}, size = 'middle'} = props
  return (
    <div className={style.container}>
      <div className={style.tipText} style={tipStyle}>{tipText}</div>
      <div className={`${style.mainText} ${style[size]}`} style={mainStyle}>{mainText}</div>
    </div>
  )
}

export default Title
