import React, {useEffect, useState} from 'react'

import style from './style.module.scss'

function ClassificationSelector(props) {
  const {categorys = [], onChange, activeKey} = props
  const [currentKey, changeKey] = useState(activeKey)
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentKey)
    }
  }, [currentKey, onChange])
  return (
    <div className={style.classifyFilter__area}>
      <div className={style.classifyFilter__name}>分类导航：</div>
      <div className={style.classifyFilter__list}>
        {categorys.map(({key, name, count}) => {
          return (
            <div key={key} className={`${style.classifyFilter__item} ${key === (activeKey||currentKey) ? style['classifyFilter__item--active'] : ''}`} onClick={() => {
              changeKey(key)
            }} >
              {name}{count > -1 ? '（' + (count || 0) + '）' : ''}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ClassificationSelector
