import React from 'react';
import style from './style.module.scss'

function Footer() {
  return (
    <div className={style['footer']}>
      <div className={style['footer__link']}>
        <a href="http://www.xartvu.sn.cn/" target="_blank" rel="noreferrer">学校首页</a>
      </div>
      <div className={style['footer__text']}>
        <span>备案号：陕ICP备08005925号-1</span>
        <span>地址：西安市新城区金花南路11号</span>
        <span>联系电话：029-83233135</span>
      </div>
      <div>技术支持：福建省华渔教育科技有限公司</div>
    </div>
  )
}

export default Footer
