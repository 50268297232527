import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import UIPagination from '../../../components/UIPagination'
import ClassificationSelector from './ClassificationSelector'
import {getNotice, getDynamic, getForecast, getAllData} from '../../../api/request'
import {INFO_TYPE, INFO_TYPE_DICT} from 'consts/common'
import style from '../style.module.scss'
import Empty from '../../../components/Empty'
import {ACTIVITY, TRENDS} from '../../../components/LeftMenu/const'
import {NOTICE} from '../../Detail/const'
import {useHistory} from 'react-router-dom'

const PAGE_SIZE = 12
const requestDict = {
  [INFO_TYPE.notice]: getNotice,
  [INFO_TYPE.dynamic]: getDynamic,
  [INFO_TYPE.forecast]: getForecast,
  [INFO_TYPE.whole]: getAllData
}

function Info(props) {
  const getClassify = useMemo(() => {
    const allTypeKeys = Object.keys(INFO_TYPE)
    return allTypeKeys.find((key) => key === props.classify) ? props.classify : INFO_TYPE.whole
  }, [props.classify])
  const {
    keyword = '', handleChangeType
  } = props
  const history = useHistory()
  const [isLoading, setLoading] = useState(true)
  const [filter, setFilter] = useState({
    page: 0,
    keyword,
    type: getClassify
  })
  const [result, setResult] = useState({
    total: 0,
    dataset: []
  })
  // 提取结果
  const updateResult = (res) => {
    const {
      data_list = [],
      total_elements = 0
    } = res?.data || {}

    setResult({
      total: total_elements,
      dataset: data_list.map(({
                                id,
                                title,
                                publish_time,
                                parent_title_id
                              }) => {
        return {
          title,
          id,
          parent_title_id,
          date: moment(publish_time).format('YYYY-MM-DD')
        }
      })
    })
    setLoading(false)
  }
  useEffect(() => {
    setFilter({
      page: 0,
      keyword,
      type: getClassify
    })
  }, [keyword, props.classify, props.refresh, getClassify])
  // 资讯搜索
  useEffect(() => {
    setLoading(true)
    requestDict[filter.type] && requestDict[filter.type](filter.page, PAGE_SIZE, filter.keyword).then(updateResult)
  }, [filter])
  return (
    <div style={{marginBottom: -16}}>
      <ClassificationSelector
        onChange={(key) => {
          if (filter.type !== key) {
            handleChangeType(key)
            setFilter({
              ...filter,
              page: 0,
              type: key
            })
          }
        }}
        activeKey={getClassify}
        categorys={[
          {key: INFO_TYPE.whole, name: '全部'},
          {key: INFO_TYPE.notice, name: '通知公告'},
          {key: INFO_TYPE.dynamic, name: '图书馆动态'},
          {key: INFO_TYPE.forecast, name: '活动预告'}
        ]}
      />
      <div className={style.searchResult__list}>
        {!isLoading && result.dataset.length === 0 ? <Empty/> : result.dataset.map((item, idx) => {
          const {id, date, title, parent_title_id} = item
          let kindName = ''
          let kindStyle = ''
          let routerPath = ''
          switch (INFO_TYPE_DICT[parent_title_id]) {
            case INFO_TYPE.notice: {
              kindName = '通知'
              routerPath = NOTICE
              kindStyle = style['searchResult__item__tag--notice']
              break
            }
            case INFO_TYPE.dynamic: {
              kindName = '动态'
              routerPath = TRENDS
              kindStyle = style['searchResult__item__tag--dynamic']
              break
            }
            case INFO_TYPE.forecast: {
              kindName = '活动'
              routerPath = ACTIVITY
              kindStyle = style['searchResult__item__tag--forecast']
              break
            }
            default: {
              break
            }
          }
          return (
            <div key={id} className={style.searchResult__item}
                 onClick={() => history.push(`/detail/${routerPath}/${id}`)}>
              <div className={style.searchResult__item__dot}/>
              <div className={`${style.searchResult__item__tag} ${kindStyle}`}>{kindName}</div>
              <span className={style.searchResult__item__txt}>
                  {title}
                </span>
              <span className={style.searchResult__item__nyr}>{date}</span>
            </div>
          )
        })}
      </div>
      <UIPagination total={result.total} current={filter.page + 1} pageSize={PAGE_SIZE} onChange={(page) => {
        setFilter({
          ...filter,
          page: page - 1
        })
      }}/>
    </div>
  )
}

export default Info
