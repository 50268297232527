import {useEffect, useState} from 'react'
import {getContentData} from '../api/request'

const useHtmlContent = (id) => {
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const initFn = async () => {
      setLoading(true)
      getContentData(id).then((res) => {
        setData(res?.data || {})
      }).finally(() => {
        setLoading(false)
      })
    }
    initFn()
  }, [id])

  return [data, isLoading]
}

export default useHtmlContent
