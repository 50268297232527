import React, {useCallback} from 'react'
import style from './style.module.scss'
import {NOTICE} from "../../pages/Detail/const";
import moment from "moment";
import {useHistory} from "react-router-dom";
import Empty from "../Empty";

function InfoList({data, emptyMinHeight, onClick}) {
  const history = useHistory()
  const handleClick = useCallback((id) => {
    if (typeof onClick === 'function') {
      onClick(id);
      return;
    }
    history.push(`/detail/${NOTICE}/${id}`)
  }, [history, onClick]);
  return (
    <>
      {!!data && (
        !data.length
          ? <Empty icon={'list'} tip={'暂无内容'} minHeight={emptyMinHeight || 300} />
          : (
            data.map((item) => {
              const {title, publish_time, id} = item || {}
              return (
                <div key={id} className={style.item} onClick={() => {handleClick(id)}}>
                  <span className={style.label} title={title}>{title}</span>
                  <span className={style.date}>
                    {moment(publish_time).format('YYYY-MM-DD')}
                  </span>
                </div>
              )
            })
          )
      )}
    </>
  )
}

export default InfoList
