import React from 'react'
import style from './index.module.scss'
import ListItem from 'components/ListItem'
import usePageData from '../../hook/usePageData'
import {getDynamic} from '../../api/request'
import UIPagination from '../../components/UIPagination'
import {useHistory} from 'react-router-dom'
import {TRENDS} from '../../components/LeftMenu/const'
import Empty from '../../components/Empty'

const PAGE_SIZE = 10
const getListFn = (page, size, ...props) => {
  return getDynamic(page, size, ...props).then(
    (res) => {
      const {
        data: {
          data_list = [],
          total_elements = 0
        } = {}
      } = res || {}
      return {total: total_elements, data: data_list}
    }
  )
}

function Trends() {
  const history = useHistory()
  const {current, total, data, setCurrent, isLoading} = usePageData(getListFn, {defaultPageSize: PAGE_SIZE})
  return (
    <div className={style.container}>
      <div className={style.list}>
        {!isLoading && data.length === 0 ? <Empty icon={'list'} tip={'暂无内容'} minHeight={720}/> : data.map((item) => {
          return <ListItem key={item.id} data={item} onClick={() => {
            history.push(`/readingActivity/${TRENDS}/${item.id}`)
          }}/>
        })}
      </div>
      <UIPagination onChange={(page) => {
        setCurrent(page)
      }} current={current} pageSize={PAGE_SIZE} total={total}/>
    </div>
  )
}

export default Trends
