import React, {useCallback, useEffect, useState} from 'react'
import style from './index.module.scss'
import { Form, Input, Button, Select, Checkbox, Modal } from 'antd'
import { getPlatforms, login } from 'api/request'

const inputStyle = { borderRadius: 50, paddingLeft: 24, paddingRight: 24 }
const REMEMBER_ME = 'REMEMBER_ME'
const PASSWORD = 'password'
const PLATFORM = 'platform'
const USERNAME = 'username'

function getMe() {
  return JSON.parse(window.localStorage.getItem(REMEMBER_ME))
}

function setMe(obj) {
  window.localStorage.setItem(REMEMBER_ME, JSON.stringify(obj))
}

function removeMe() {
  window.localStorage.removeItem(REMEMBER_ME)
}

function Login() {
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const handleFinish = useCallback((values) => {
    const platform = values[PLATFORM]
    const username = values[USERNAME]
    const password = values[PASSWORD]
    const data = { platform, username, password }
    login(data).then(({data, message}) => {
      if (!data) {
        Modal.error({
          wrapClassName: style['custom-ant'],
          content: message || '登录错误',
          okText: '确定',
        })
        return
      }

      const redirectUrl = data.redict_url
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
    })
  }, [])

  const toggleRememberMe = useCallback((v) => {
    if (v) {
      setMe(form.getFieldsValue([PLATFORM, USERNAME, PASSWORD, REMEMBER_ME]))
    } else {
      removeMe()
    }
  }, [])

  const handleChange = useCallback((event) => {
    toggleRememberMe(event.target.checked)
  }, [])

  const [options, setOptions] = useState([]);
  useEffect(() => {
    getPlatforms().then((res) => {
      setOptions(res)
    })
  }, [])

  useEffect(() => {
    forceUpdate({})
  }, [])

  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    setInitialValues(getMe() || {})
  }, [])

  if (!initialValues || !options.length) {
    return null
  }

  return (
    <div className={style['login-page']}>
      <div className={`${style['login']} ${style['custom-ant']}`}>
        <div className={style['login__header']}>
          <div className={style['login__title']}>
            登录
          </div>
        </div>
        <div className={style['login__body']}>
          <Form
            size="large"
            form={form}
            initialValues={initialValues}
            onFinish={handleFinish}
          >
            <Form.Item
              name={PLATFORM}
              rules={[{ required: true, message: '请选择' }]}
            >
              <Select
                dropdownClassName={style['custom-ant__select-dropdown']}
                fieldNames={{ label: 'name', value: 'code' }}
                options={options}
                placeholder="请选择"
                rules={[{ required: true, message: '请选择' }]}
              />
            </Form.Item>
            <Form.Item
              name={USERNAME}
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input style={inputStyle} placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item
              name={PASSWORD}
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password style={inputStyle} placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
              name={REMEMBER_ME}
              valuePropName="checked"
              dependencies={[PLATFORM, USERNAME, PASSWORD]}
              rules={[
                () => ({
                  validator(_, value) {
                    toggleRememberMe(value)
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Checkbox onChange={handleChange}>
                下次自动登录
              </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
              {() => (
                <Button
                  htmlType="submit"
                  type="primary"
                  block
                  shape="round"
                  disabled={
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                    !(form.getFieldValue(PLATFORM) && form.getFieldValue(USERNAME) && form.getFieldValue(PASSWORD))
                  }
                >
                  登录
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
