import React, {useCallback, useEffect, useState} from 'react'
import {Image} from 'antd';
import style from '../style.module.scss'
import {BASE_URL} from 'api/config';
import {searchBooks} from 'api/request'
import {placeholder} from 'consts/imgConst';
import Content from 'components/Content'
import UIPagination from 'components/UIPagination';
import Empty from 'components/Empty';

const getSlice = (str, keyword) => {
  if (!str) {
    return ['']
  }

  const index = str.indexOf(keyword)
  if (index === -1) {
    return [str]
  }

  const keywordLen = keyword.length
  const s1 = str.slice(0, index)
  const s2 = str.slice(index, index + keywordLen)
  const s3 = str.slice(index + keywordLen)
  return [s1, s2, s3]
};

function Book(props) {
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);
  const handleSearch = useCallback((keyword, page) => {
    searchBooks({search_text: keyword, page})
      .then(res => {
        const {data_list, total_elements} = res?.data || {}
        const data = data_list?.map((item) => {
          const nameSlice = getSlice(item.bk_name, keyword)
          const authorSlice = getSlice(item.bk_auth, keyword)
          return ({
            ...item,
            nameSlice,
            authorSlice
          })
        });
        setData(data || []);
        setCurrent(page || 0);
        setTotal(total_elements || 0);
      })
      .catch(() => {
        setData([]);
        setCurrent(0);
        setTotal(0);
      })
  }, [])

  useEffect(() => {
    handleSearch(props.keyword, 0)
  }, [props.keyword, props.refresh, handleSearch]);

  const handleChange = useCallback((current) => {
    handleSearch(props.keyword, current - 1)
  }, [props.keyword, handleSearch]);

  return (
    <div className={style['book']}>
      <Content className={style['book__content']} style={{padding: 32}}>
        <div className={style['book__list']}>
          {!!data && (
            !data.length
              ? <Empty minHeight={'calc(100vh - 68px - 142px - 92px - 80px)'} />
              : (data?.map(item => {
                  const {
                    bk_id, bk_cover, prs_nme, pub_tme, borrowed_times,
                    place, store, store_detail, total, avaliable, nameSlice, authorSlice
                  } = item || {}
                  return (
                    <div className={style['book__item']} key={bk_id}>
                      <div className={style['book__cover']}>
                        <Image height="100%" preview={false} src={`${BASE_URL}${bk_cover || ''}`} fallback={placeholder} />
                      </div>
                      <div className={style['book__text']}>
                        <div className={style['book__title']}>
                          {nameSlice[0]}{nameSlice[1] && <span className={style['book__mark']}>{nameSlice[1]}</span>}{nameSlice[2]}
                        </div>
                        <div className={style['book__info']}>
                          <div>
                            作者：{authorSlice[0]}{authorSlice[1] && <span className={style['book__mark']}>{authorSlice[1]}</span>}{authorSlice[2]}
                          </div>
                          <div className={style['book__info--multiple']}>
                            <div>出版社：<span>{prs_nme}</span></div>
                            <div>出版时间：<span>{pub_tme}</span></div>
                          </div>
                          <div>借阅量：<span>{borrowed_times}次</span></div>
                        </div>
                        <div className={style['lib']}>
                          <div className={style['lib__title']}>在馆情况</div>
                          <div className={style['lib__list']}>
                            <div className={style['lib__item']}>索书号<span title={place}>{place}</span></div>
                            <div className={style['lib__item']}>所在馆<span title={store}>{store}</span></div>
                            <div className={style['lib__item']}>所在馆藏地点<span title={store_detail}>{store_detail}</span></div>
                            <div className={style['lib__item']}>在馆数/馆藏总数<span>{avaliable}/{total}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )
          )}
        </div>
      </Content>
      <UIPagination
        style={{marginTop: 16, marginBottom: -16}}
        onChange={handleChange}
        current={current + 1}
        pageSize={10}
        total={total}
      />

    </div>
  )
}

export default Book
