import React from 'react'
import style from './style.module.scss'

function Page(props) {
  return (
    <div className={`${style['page']} ${props.className || ''}`}>
      <div className={style['page__container']}>
        <div className={style['page__body']}>
          {props.children}
        </div>
      </div>
      {props.extraContent}
    </div>
  )
}

export default Page
