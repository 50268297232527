import React from 'react'
import { Pagination } from 'antd'

import style from './index.module.scss'

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <div className={'ant-pagination-item-link'}>上一页</div>
  }
  if (type === 'next') {
    return <div className={'ant-pagination-item-link'}>下一页</div>
  }
  return originalElement
}
function UIPagination(props) {
  return (
    <Pagination {...props} className={style['pagination']} itemRender={itemRender} showSizeChanger={false} />
  )
}

export default UIPagination
