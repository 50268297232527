import React from 'react'
import style from './style.module.scss'

function Content(props) {
  return (
    <div className={`${style['content']} ${props.className}`} style={props.style}>
      {props.children}
    </div>
  )
}

export default Content
