import React from 'react'
import {Image} from "antd";
import style from './style.module.scss'
import EditorShow from 'components/EditorShow'
import useHtmlContent from 'hook/useHtmlContent'
import Content from 'components/Content'
import BreadcrumbWithSearch from 'components/BreadcrumbWithSearch';
import {BASE_URL} from "api/config";
import {placeholder} from "consts/imgConst";

function RecommendationDetail(props) {
  const [data, isLoading] = useHtmlContent(props.match.params.id)
  const {title_type, content_type, html, plain_text, link, brief, title, author, cover_image} = data || {}

  return (
      <div className={style['detail']}>
        <BreadcrumbWithSearch
          breadcrumb={[
            {name: '每月好书推荐', href: '/readingActivity/Recommendation'},
            {name: '好书详情'},
          ]}
        />
        <div className={style['detail__main']}>
          <div className={style['detail__side']}>
            <Content style={{minHeight: '100%', paddingLeft: 24, paddingRight: 24}}>
              <div className={style['detail__cover-wrap']}>
                <div className={style['detail__cover']}>
                  <Image height="100%" preview={false} src={`${BASE_URL}${cover_image || ''}`} fallback={placeholder} />
                </div>
              </div>
              <div className={style['detail__title']}>{title}</div>
              <ul className={style['detail__info']}>
                <li>作者：{author}</li>
                {brief?.split('\n').map((item, index) => <li key={index}>{item}</li>)}
              </ul>
            </Content>
          </div>
          <div className={style['detail__content']}>
            <Content style={{minHeight: '100%'}}>
              {!isLoading && <EditorShow {...{title_type, content_type, html, plain_text, link}} />}
            </Content>
          </div>
        </div>
    </div>
  )
}

export default RecommendationDetail
