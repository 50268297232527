import React, {useCallback, useState} from 'react'
import {withRouter} from 'react-router'
import queryString from 'query-string'
import style from './style.module.scss'
import {Input} from 'antd'
import {KEYWORD_MAX_LEN} from 'consts/common'

const types = [
  {
    value: 'book',
    label: '图书搜索',
  },
  {
    value: 'info',
    label: '资讯搜索',
  },
];

/**
 *
 * @param props.onSearch 搜索回调
 * @param props.type 搜索类型：'book'-图书搜索（默认值），'info'-资讯搜索
 * @param props.selectTriggerTypeChange 下拉选项切换触发搜索类型调整
 * @param props.value 关键词
 * @returns {JSX.Element}
 * @constructor
 */
function Search(props) {
  // 获取下拉选项
  const getOptions = useCallback(() => {
    return types
  }, []);

  // 搜索子类型，通过下拉切换
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(props.type || getOptions()[0].value);
  const handleClickCurrent = useCallback(() => {
    // debugger
    setActive(!active);
  }, [active]);

  const handleSelectChange = useCallback((event) => {
    const selected = event.target.dataset.value
    typeof props.onChange === 'function' && props.onChange({
      value: queryString.parse(props.location.search).keyword || '',
      option: queryString.parse(props.location.search).option || '',
      selected
    })
    setSelected(selected);
    setActive(!active);
  }, [active, props.location.search, props.onChange]);

  const handleLeaveSelect = useCallback(() => {
    setActive(false);
  }, []);

  const handleSearch = useCallback((value) => {
    const option = queryString.parse(props.location.search).option || ''
    if (typeof props.onSearch === 'function') {
      props.onSearch({value, selected, option});
      return;
    }

    props.history.push({
      pathname: `/searchResult/${selected}`,
      search: `keyword=${encodeURIComponent(value)}&option=${option}`,
    })
  }, [selected, props.location.search, props.onSearch]);

  return (
    <div className={style['search__bar']}>
      <div className={`${style['select']} ${active ? style['select--active'] : ''}`}>
        <div onMouseLeave={handleLeaveSelect}>
          <div className={style['select__current']} onClick={handleClickCurrent}>
            {getOptions().find(({value})=> value === selected).label}
          </div>
          <div className={style['select__options']}>
            {getOptions().map(({value, label}) => {
              const className = `${style['select__option']} ${value === selected ? style['select__option--active']: ''}`
              return (
                <div onClick={handleSelectChange} className={className} key={value} data-value={value} title={label}>{label}</div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={style['search__input']}>
        <Input.Search
          key={props.type}
          maxLength={KEYWORD_MAX_LEN}
          defaultValue={props.value}
          placeholder="请输入搜索内容"
          onSearch={handleSearch}
        />
      </div>
    </div>
  )
}

export default withRouter(Search)
