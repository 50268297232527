import Container from "../pages/Container";
import Home from '../pages/Home';
import {ReadingActivity, RecommendationDetail} from "../pages/ReadingActivity";
import LibraryOverview from '../pages/LibraryOverview'
import ReaderGuide from '../pages/ReaderGuide'
import Notice from '../pages/Notice'
import Login from '../pages/Login'
import SearchResult from '../pages/SearchResult'
import Detail from '../pages/Detail'


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    exact: true,
    path: '/',
    component: Home
  },
  // 登录
  {
    path: '/login',
    component: Login
  },
  {
    component: Container,
    routes: [
      // 阅读活动推广
      {
        component: ReadingActivity,
        exact: true,
        path: '/readingActivity/:type',
        title: '阅读活动推广',
        subTitle: 'READING ACTIVITIES',
        bannerType: '1',
      },
      // 好书推荐 - 详情
      {
        path: '/readingActivity/Recommendation/:id',
        component: RecommendationDetail
      },
      // 动态/预告 详情
      {
        path: '/readingActivity/:type/:id',
        exact: true,
        component: Detail
      },
      // 搜索结果
      {
        path: '/searchResult/:type', // type: 纸图-book
        component: SearchResult
      },
      // 读者咨询
      {
        path: '/readerGuide/:type/:qid',
        component: ReaderGuide,
        title: '读者指南',
        subTitle: 'READER\'S GUIDE',
        bannerType: '1',
      },
      // 读者咨询
      {
        path: '/readerGuide/:type',
        component: ReaderGuide,
        title: '读者指南',
        subTitle: 'READER\'S GUIDE',
        bannerType: '1',
      },
      // 图书馆概况
      {
        path: '/libraryOverview/:type',
        component: LibraryOverview,
        exact: true,
        title: '图书馆概况',
        subTitle: 'LIBRARY OVERVIEW',
        bannerType: '2',
      },
      // 规章制度 - 详情页
      {
        path: '/libraryOverview/:type/:id',
        exact: true,
        component: Detail
      },
      // 通知
      {
        path: '/notice',
        exact: true,
        component: Notice
      },
      // 通知 详情
      {
        path: '/detail/:type/:id',
        exact: true,
        component: Detail
      },
    ]
  },
]
