import React, {useCallback} from 'react'
import { renderRoutes } from "react-router-config";
import { matchPath } from "react-router";
import Header from './Header'
import Footer from './Footer'
import style from './style.module.scss'
import Title from 'components/Title';

function Container(props) {
  const getCurrent = useCallback(() => {
    const ret = props.route.routes.find(({path, showBanner}) => (
      matchPath(
        props.location.pathname, {
          path,
          exact: true,
        }
      )
    ))
    return ret || {}
  }, [props.location.pathname, props.route.routes]);

  const {bannerType, title, subTitle} = getCurrent()
  const banner = bannerType && (
    <Title mainText={title} tipText={subTitle} mainStyle={{color: '#fff'}} tipStyle={{color: '#fff'}} />
  );

  return (
    <div className={style['container']}>
      <Header banner={banner} bannerType={bannerType} />
      <div className={style['body']}>
        {renderRoutes(props.route.routes)}
      </div>
      <div className={style['footer__wrap']}>
        <Footer />
      </div>
    </div>
  )
}
export {Footer, Header}
export default Container
