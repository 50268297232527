import React, { useState, useEffect } from 'react'
import Title from 'components/Title';
import style from './index.module.scss'
import Recommend from './component/Recommend'
import Dynamic from './component/Dynamic'
import Notice from './component/Notice'
import classnames from 'classnames'
import { getBookRecommend, getActivityNotice, getLibraryDynamic } from '../../../../api/request'

function ReadingActivitiesPromotion(props) {
  const [key, setKey] = useState('1')
  const [recommend, setRecommend] = useState(null)
  const [dynamic, setDynamic] = useState(null)
  const [activity, setActivity] = useState(null)
  useEffect(() => {
    if (key === '1') {
      if (!recommend?.length) {
        getBookRecommend()
          .then((res) => {
            setRecommend(res?.data?.data_list || [])
          })
          .catch(() => {
            setRecommend([])
          })
      }
    } else if (key === '2') {
      if (!dynamic?.length) {
        getLibraryDynamic()
          .then((res) => {
            setDynamic(res?.data?.data_list || [])
          })
          .catch(() => {
            setDynamic([])
          })
      }
    } else if (key === '3') {
      if (!activity?.length) {
        getActivityNotice()
          .then((res) => {
            setActivity(res?.data?.data_list || [])
          })
          .catch(() => {
            setActivity([])
          })
      }
    }
  }, [key])

  return (
    <div className={style.container}>
      <Title mainText={'阅读活动推广'} tipText={'READING ACTIVITIES'} mainStyle={{color: 'rgb(148, 114, 78)'}} size={'small'}/>
      <div className={style.tabs}>
          <div className={classnames(style.tabItem, {[style.active] : key === '1'})} onClick={() => {setKey('1')}}>
            好书推荐
          </div>
          <div className={classnames(style.tabItem, {[style.active]: key === '2'})} onClick={() => {setKey('2')}}>
            图书馆动态
          </div>
          <div className={classnames(style.tabItem, {[style.active]: key === '3'})} onClick={() => {setKey('3')}}>
            活动预告
          </div>
      </div>
      <Recommend data={recommend} style={{display: key === '1' ? 'block' : 'none'}} />
      <Dynamic data={dynamic} style={{display: key === '2' ? 'block' : 'none'}} />
      <Notice data={activity} style={{display: key === '3' ? 'block' : 'none'}} />
    </div>
  )
}

export default ReadingActivitiesPromotion
