import React, {useEffect, useState} from 'react'
import style from './style.module.scss'
import {withRouter} from 'react-router'
import {getQuest} from '../../../../api/request'
import usePageData from '../../../../hook/usePageData'
import UIPagination from '../../../../components/UIPagination'
import {DownOutlined, UpOutlined} from '@ant-design/icons'

const MAX_LENGTH = 170
const getListFn = (page, size, ...props) => {
  return getQuest(page, size, ...props).then(
    (res) => {
      const {
        data: {
          data_list = [],
          total_elements = 0
        } = {}
      } = res || {}
      return {
        total: total_elements, data: data_list.map((item) => {
          const {plain_text} = item
          return {
            ...item, needShowOpenBtn: plain_text.length > MAX_LENGTH, open: false
          }
        })
      }
    }
  )
}

function QuestList(props) {
  const {match: {params: {qid = ''}}} = props
  const [id, setId] = useState(null)
  const [tempDict, setTempDict] = useState({})
  const {current, total, data, setCurrent} = usePageData(getListFn, {defaultPageSize: 15})
  useEffect(() => {
    setId(qid)
  }, [qid])
  useEffect(() => {
    if (id) {
      if (data && data.length > 0) {
        const el = document.getElementById(id)
        if (el) {
          document.documentElement.scrollTop = el.getClientRects()[0].top
          setId(null)
        }
      }
    }
    setTempDict({})
  }, [data, id])
  return (
    <div className={style.container}>
      <div className={style.questList}>
        {data.map((item) => {
          const {title, plain_text, id, needShowOpenBtn} = item
          const showAll = tempDict[id]
          return <div id={id} key={id} className={style.questItem}>
            <div className={style.title}>Q:{title}</div>
            <div className={style.answer}>
              A:{showAll ? plain_text : plain_text.slice(0, MAX_LENGTH)}
              {needShowOpenBtn ?
                <span className={style.openBtn} onClick={() => {
                  setTempDict({...tempDict, [id]: !showAll})
                }}>
                {showAll ? '收起' : '展开'}<span  className={style.openBtnIcon}>{showAll ? <UpOutlined/> : <DownOutlined/>}</span>
              </span>
                : ''}
            </div>
          </div>
        })}
      </div>
      <UIPagination
        onChange={(page) => {
          setCurrent(page)
        }}
        pageSize={15}
        current={current}
        total={total}
      />
    </div>
  )
}

export default withRouter(QuestList)
