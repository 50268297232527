import React, {useCallback, useEffect, useState} from 'react'
import { Carousel, Image } from 'antd';
import style from './style.module.scss'
import Title from 'components/Title';
import {getList} from 'api/request';
import {placeholder} from 'consts/imgConst'
import {BASE_URL} from 'api/config';

const PAGE_SIZE = 3

function Friends() {
  const [friends, setFriends] = useState([]);
  useEffect(() => {
    const params = {parent_title_id: '122238991560867840'}
    getList(params).then((res) => {
      setFriends(res?.data?.data_list || [])
    })
  }, [])

  const getFriendsGroup = useCallback((friends) => {
      let ret = []
      const len = friends.length
      for (let i = 0, len2 = Math.ceil(len / PAGE_SIZE); i < len2; i++) {
        ret.push(friends.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE))
      }
      return ret
    }, [])

  const handleClick = useCallback((link) => {
    window.open(link);
  }, []);

  return (
    <div className={style['friends']}>
      <Title mainText={'友情链接'} tipText={'FRIENDSHIP LINK'} mainStyle={{color: '#fff'}} tipStyle={{color: '#fff'}}/>
      <div className={style['friends__body']}>
        <Carousel dots={false} autoplay={friends.length > 3}>
          {getFriendsGroup(friends).map((group, current) => (
            <div key={current}>
              <div className={style['friends__list']}>
                {group.slice(0, 3).map(({title, link, id, cover_image}, index) => (
                  <div className={style['friends__item-wrap']} key={id} onClick={() => handleClick(link)}>
                    <div className={style['friends__item']}>
                      <div className={style['friends__num-wrap']}>
                        <div className={style['friends__num']}>
                          {`0${(index + 1) + current * PAGE_SIZE}`}
                        </div>
                      </div>
                      <div className={style['friends__cover']}>
                        <Image preview={false} src={`${BASE_URL}${cover_image}`} fallback={placeholder} />
                      </div>
                      {title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Friends
