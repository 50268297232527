import React from 'react'
import style from './index.module.scss'
import UIPagination from '../../components/UIPagination'
import BreadcrumbWithSearch from '../../components/BreadcrumbWithSearch'
import {useHistory} from 'react-router-dom'
import {getNotice} from '../../api/request'
import usePageData from '../../hook/usePageData'
import moment from 'moment'
import Empty from '../../components/Empty'
import {NOTICE} from '../Detail/const'

const getListFn = (page, size, ...props) => {
  return getNotice(page, size, ...props).then(
    (res) => {
      const {
        data: {
          data_list = [],
          total_elements = 0
        } = {}
      } = res || {}
      return {total: total_elements, data: data_list}
    }
  )
}

function Notice() {
  const history = useHistory()
  const {current, total, data, setCurrent, isLoading} = usePageData(getListFn)
  return (
    <div className={style.container}>
      <BreadcrumbWithSearch breadcrumb={[{name: '通知公告'}]}/>
      <div className={style.notice}>
        {!isLoading && data.length === 0 ? <Empty icon={'list'} tip={'暂无内容'} minHeight={720}/> : data.map((item) => {
          const {title, publish_time, id} = item
          return <div key={id} className={style.noticeItem} onClick={
            () => history.push(`/detail/${NOTICE}/${id}`)
          }>
          <span className={style.label}>
            {title}
          </span>
            <span className={style.date}>
            {moment(publish_time).format('YYYY-MM-DD')}
          </span>
          </div>
        })}
      </div>
      <UIPagination onChange={(page) => {
        setCurrent(page)
      }} current={current} pageSize={12} total={total}/>
    </div>
  )
}

export default Notice
