import React from 'react'
import style from './index.module.scss'

function LeftMenu(props) {
  const {activeKey = '', menus = []} = props
  return (
    <div className={style.container}>
      {menus.map((menu) => {
        const {key, name, icon, handleClick} = menu
        return <div key={key}
                    className={`${style.item} ${activeKey.toLowerCase() === key.toLowerCase() ? style.activeItem : ''}`}
                    onClick={handleClick}>
          <div className={`${style.icon} ${style[icon]}`}/>
          <div>{name}</div>
        </div>
      })}
    </div>
  )
}

export default LeftMenu
