import {useState, useEffect} from 'react'
import {debounce} from 'lodash-es'

const getSize = () => {
  const width = window.innerWidth
  const height = window.innerHeight
  return {
    width,
    height,
    contentWidth: Math.min(width, 1920)  - 259 * 2,
    contentHeight: Math.min(height, 1080) - 120 * 2,
    type: (width < 1790) ? 'small' : 'normal'
  }
}

const useSize = (callback) => {
  const [size, setSize] = useState(getSize())
  useEffect(() => {
    const handler = () => {
      setSize(getSize())
      typeof callback === 'function' && callback(getSize())
    }

    const debounced = debounce(handler, 200)
    window.addEventListener('resize', debounced)
    return () => {
      window.removeEventListener('resize', debounced)
    }
  }, [callback])
  return size
}
export {
  getSize
}
export default useSize
