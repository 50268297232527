// 入馆须知
export const ENTRANCE_RULES = 'EntranceRules'
export const ENTRANCE_RULES_ID = '122323060065828864'
// 读者咨询
export const READERS_CONSULTING = 'ReadersConsulting'
export const READERS_CONSULTING_ID = '122323139270017024'

export const READER_GUIDE_MENUS = [
  {key: ENTRANCE_RULES, name: '入馆须知', icon: ENTRANCE_RULES},
  {key: READERS_CONSULTING, name: '读者咨询', icon: READERS_CONSULTING}
]

export const READER_GUIDE_DICT = {
  [ENTRANCE_RULES]: ENTRANCE_RULES_ID,
  [READERS_CONSULTING]: READERS_CONSULTING_ID
}

// 图书馆介绍
export const LIBRARY_INTRODUCTION = 'LibraryIntroduction'
export const LIBRARY_INTRODUCTION_ID = '122321877677899776'
// 馆室介绍
export const ROOM_INTRO = 'RoomIntro'
export const ROOM_INTRO_ID = '122321915701362688'
// 馆藏布局
export const LIBRARY_LAYOUT = 'LibraryLayout'
export const LIBRARY_LAYOUT_ID = '122321940658520064'
// 规章制度
export const REGULATION = 'Regulation'
export const REGULATION_ID = '122321963416813568'
export const LIBRARY_OVERVIEW_MENUS = [
  {key: LIBRARY_INTRODUCTION, name: '图书馆介绍', icon: LIBRARY_INTRODUCTION},
  {key: ROOM_INTRO, name: '馆室介绍', icon: ROOM_INTRO},
  {key: LIBRARY_LAYOUT, name: '馆藏布局', icon: LIBRARY_LAYOUT},
  {key: REGULATION, name: '规章制度', icon: REGULATION}
]
export const LIBRARY_OVERVIEW_DICT = {
  [LIBRARY_INTRODUCTION]: LIBRARY_INTRODUCTION_ID,
  [ROOM_INTRO]: ROOM_INTRO_ID,
  [LIBRARY_LAYOUT]: LIBRARY_LAYOUT_ID,
  [REGULATION]: REGULATION_ID
}
export const RECOMMENDATION = 'Recommendation'
export const TRENDS = 'Trends'
export const ACTIVITY = 'Activity'
export const READING_ACTIVITY_MENUS = [
  {key: RECOMMENDATION, name: '每月好书推荐', icon: RECOMMENDATION},
  {key: TRENDS, name: '图书馆动态', icon: TRENDS},
  {key: ACTIVITY, name: '活动预告', icon: ACTIVITY}
]
export const READING_ACTIVITY_TITLE_DICT = {
  [RECOMMENDATION]: '每月好书推荐',
  [TRENDS]: '图书馆动态',
  [ACTIVITY]: '活动预告'
}
