import React from 'react';
import { renderRoutes } from 'react-router-config';
import routes from './routes/index';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from  'react-redux';
import store from './store/index';
import 'assets/index.scss'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        { renderRoutes(routes) }
      </BrowserRouter>
    </Provider>
  );
}

export default App;
