import Axios from './config'

export const getContentData = (id) => {
  return Axios.get(`/v1/xartvu/lib/news/${id}/detail`)
}
export const increaseCount = (id) => {
  return Axios.post(`/v1/xartvu/lib/news/${id}/increase`)
}
const getListData = (parent_title_id = '', page = 0, size = 20, find = '') => {
  return Axios.post(`/v1/xartvu/lib/news/page`, {
    parent_title_id,
    page_request_data: {
      page: page,
      size: size
    },
    title: find
  })
}
const getListDataByIds = (parent_title_id_list = [], page = 0, size = 20, find = '') => {
  return Axios.post(`/v1/xartvu/lib/news/search`, {
    parent_title_id_list,
    page_request_data: {
      page: page,
      size: size
    },
    title_type:'CONTENT',
    title: find
  })
}

export const getList = ({parent_title_id, page = 0, size = 20}) => {
  return getListData(parent_title_id, page, size)
}

export const getQuest = (page = 0, size = 5) => {
  return getListData('122323139270017024', page, size)
}
export const getNotice = (page, size, find) => {
  return getListData('122238737590517760', page, size, find)
}
export const getAllData = (page, size, find) => {
  return getListDataByIds(['122238737590517760', '122239245597278208', '122239268851548160'], page, size, find)
}
export const getDynamic = (page, size, find) => {
  return getListData('122239245597278208', page, size, find)
}
export const getForecast = (page, size, find) => {
  return getListData('122239268851548160', page, size, find) // 活动预告
}
export const getRegulation = (page, size, find) => {
  return getListData('126015369648275456', page, size, find) // 规章制度列表
}
export const getDigitalReading = (page, size) => {
  return getListData('122238928593879040', page, size)
}
export const getBookRecommend = (size = 12) => {
  return getListData('122239095618404352', 0, size)
}
export const getActivityNotice = () => {
  return getListData('122239268851548160', 0, 20)
}
export const getLibraryDynamic = () => {
  return getListData('122239245597278208', 0, 14)
}
export const searchBooks = ({search_text = '', page = 0, size = 10}) => {
  return Axios.post('/v1/xartvu/lib/books/search',
    {
      search_text,
      page_request_data: {
        page,
        size,
      },
    }
  )
}

export const getPlatforms = () => {
  return Axios.get(`/v1/xartvu/lib/platform/list`).then((res) => {
    return res.data || []
  })
}

export const login = (data) => {
  return Axios.post(`/v1/xartvu/lib/platform/login`, data).then((res) => {
    return res || {}
  })
}
