import React, {useMemo, useCallback, useEffect, useRef, useState} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {throttle} from 'lodash-es'
import ReactPageScroller from 'react-page-scroller'
import style from './style.module.scss'
import ReaderGuide from './component/ReaderGuide'
import DigitalBooks from './component/DigitalBooks'
import NoticeCard from './component/NoticeCard'
import SearchIndex from './component/Search'
import Friends from './component/Friends'
import LibraryOverviewCard from './component/LibraryOverviewCard'
import DigitalReadingRoom from './component/DigitalReadingRoom'
import {Footer, Header} from 'pages/Container'
import Page from './component/Page'
import ReadingActivitiesPromotion from './component/ReadingActivitiesPromotion'

const sideNavMap = {
  'search': {key: 0, name: '站内搜索'},
  'overview': {key: 1, name: '图书馆概况'},
  'guide': {key: 2, name: '读者指南'},
  'book': {key: 3, name: '数字图书'},
  'notice': {key: 4, name: '通知公告'},
  'room': {key: 5, name: '电子阅览室'},
  'promotion': {key: 6, name: '阅读活动推广'},
  'friends': {key: 7, name: '友情链接'},
  // 'contactUs': {key: 8, name: '联系我们'},
};

const sideNav = [
  sideNavMap['search'],
  sideNavMap['overview'],
  sideNavMap['guide'],
  sideNavMap['book'],
  sideNavMap['notice'],
  sideNavMap['room'],
  sideNavMap['promotion'],
  sideNavMap['friends'],
  // sideNavMap['contactUs'],
];

function Home() {
  const location = useLocation();
  const history = useHistory();
  const currentByHash = useMemo(() => {
    const DEFAULT_CURRENT = 0
    const current = parseInt(location.hash.slice(1)) || DEFAULT_CURRENT
    if (current < DEFAULT_CURRENT) {
      return DEFAULT_CURRENT
    } else if (current >= sideNav.length) {
      return sideNav.length - 1
    } else {
      return current
    }
  }, [location.hash]);

  const [initPage, setInitPage] = useState({
    [sideNavMap['overview'].key]: false, // 图书馆概况
    [sideNavMap['guide'].key]: false, // 读者指南
    [sideNavMap['notice'].key]: false, // 通知公告
    [sideNavMap['room'].key]: false, // 电子阅览室
    [sideNavMap['promotion'].key]: false, // 阅读活动推广
    [sideNavMap['friends'].key]: false, // 友情链接
  });

  const handleHashChange = useCallback(throttle((current) => {
    history.push(`/#${Math.min(current, sideNav.length - 1)}`);
  }, 500), []);

  const handleBeforePageChange = useCallback((current) => {
    if (!initPage[current]) {
      setInitPage({...initPage, [current]: true})
    }

    // const currentByHash = currentByHash;
    // handleHashChange(currentByHash === sideNavMap['contactUs'].key ? currentByHash : current)
    handleHashChange(current)
  }, [initPage, handleHashChange]);

  const isTheme1 = [
    sideNavMap['search'].key,
    sideNavMap['overview'].key,
    // sideNavMap['book'].key,
    sideNavMap['friends'].key,
    // sideNavMap['contactUs'].key,
  ].reduce((pre, cur) => {
    return pre || cur === currentByHash;
  }, false)

  const [blockScrollUp, setBlockScrollUp] = useState(false);
  const [blockScrollDown, setBlockScrollDown] = useState(false);
  const overviewRef = useRef(null);
  const guideRef = useRef(null);
  useEffect(() => {
    const overviewRefCurrent = overviewRef?.current
    const guideRefCurrent = guideRef?.current
    const enterCb = () => {
      setBlockScrollUp(true);
      setBlockScrollDown(true);
    };
    const leaveCb = () => {
      setBlockScrollUp(false);
      setBlockScrollDown(false);
    };
    overviewRefCurrent?.addEventListener('mouseenter', enterCb);
    overviewRefCurrent?.addEventListener('mouseleave', leaveCb);
    guideRefCurrent?.addEventListener('mouseenter', enterCb);
    guideRefCurrent?.addEventListener('mouseleave', leaveCb);

    return () => {
      overviewRefCurrent?.removeEventListener('mouseenter', enterCb);
      overviewRefCurrent?.removeEventListener('mouseleave', leaveCb);
      guideRefCurrent?.removeEventListener('mouseenter', enterCb);
      guideRefCurrent?.removeEventListener('mouseleave', leaveCb);
    }
  }, [location.hash]);

  useEffect(() => {
    handleHashChange(currentByHash);
  }, [currentByHash, handleHashChange]);

  return (
    <>
      <Header style={{position: 'fixed', left: 0, right: 0, top: 0}} theme={isTheme1 ? '1' : ''} />
      <ReactPageScroller
        renderAllPagesOnFirstRender
        animationTimer={500}
        blockScrollUp={blockScrollUp}
        blockScrollDown={blockScrollDown}
        // customPageNumber={Math.min(currentByHash, sideNav.length - 2)}
        customPageNumber={currentByHash}
        onBeforePageScroll={handleBeforePageChange}
      >
        <Page className={style['page--1']}>
          <SearchIndex/>
        </Page>
        <Page className={style['page--2']}>
          {initPage[sideNavMap['overview'].key] && <LibraryOverviewCard ref={overviewRef} />}
        </Page>
        <Page>
          {initPage[sideNavMap['guide'].key] && <ReaderGuide ref={guideRef} />}
        </Page>
        <Page className={style['page--4']}>
          <DigitalBooks/>
        </Page>
        <Page>
          {initPage[sideNavMap['notice'].key] && <NoticeCard/>}
        </Page>
        <Page>
          {initPage[sideNavMap['room'].key] && <DigitalReadingRoom/>}
        </Page>
        <Page>
          {initPage[sideNavMap['promotion'].key] && <ReadingActivitiesPromotion />}
        </Page>
        <Page
          className={style['page--8']}
          extraContent={<div className={style['footer__wrap']}><Footer /></div>}
        >
          {/*{(initPage[sideNavMap['friends'].key] || initPage[sideNavMap['contactUs'].key]) && <Friends/>}*/}
          {initPage[sideNavMap['friends'].key] && <Friends/>}
        </Page>
      </ReactPageScroller>
      <div className={`${style['side-nav']} ${isTheme1 ? '' : style['side-nav--theme2']}`}>
        {sideNav.map(({key, name}) => {
          let className = style['side-nav__item']
          if (currentByHash === key) {
            className = `${style['side-nav__item']} ${style['side-nav__item--active']}`
          }
          return <span key={key} onClick={() => handleHashChange(key)} className={className}>{name}</span>
        })}
      </div>
      {/*{currentByHash < sideNav.length - 2 && (*/}
      {currentByHash < sideNav.length - 1 && (
        <i onClick={() => handleHashChange(currentByHash + 1)} className={`${style['next']} ${isTheme1 ? '' : style['next--theme2']}`} />
      )}
    </>
  )
}

export default Home
