import React, {useCallback} from 'react'
import style from './index.module.scss'
import ListItem from 'components/ListItem'
import usePageData from '../../hook/usePageData'
import UIPagination from '../../components/UIPagination'
import Empty from '../../components/Empty'

const PAGE_SIZE = 10

function List({showCover, showBrief, showDate, getListFn, onClick}) {
  const getList = useCallback((page, size, ...props) => {
    return getListFn(page, size, ...props).then(
      (res) => {
        const {
          data: {
            data_list = [],
            total_elements = 0
          } = {}
        } = res || {}
        return {total: total_elements, data: data_list}
      }
    )
  }, [getListFn])
  const {current, total, data, setCurrent, isLoading} = usePageData(getList, {defaultPageSize: PAGE_SIZE})
  const handleClick = useCallback((id) => {
    if (typeof onClick === 'function') {
      onClick(id)
    }
  }, [onClick])

  return (
    <div className={style.container}>
      <div className={style.list}>
        {!isLoading && data.length === 0 ? <Empty icon={'list'} tip={'暂无内容'} minHeight={720}/> : data.map((item) => {
          return (
            <ListItem
              showCover={showCover}
              showBrief={showBrief}
              showDate={showDate}
              key={item.id}
              data={item}
              onClick={() => {handleClick(item.id)}}
            />
          )
        })}
      </div>
      <UIPagination onChange={(page) => {
        setCurrent(page)
      }} current={current} pageSize={PAGE_SIZE} total={total}/>
    </div>
  )
}

export default List
