import React from 'react'
import style from './style.module.scss'
import {Search} from 'components/Search'

/**
 * @param props.searchProps
 * @param props.breadcrumb
 * @returns {JSX.Element}
 * @constructor
 */
function BreadcrumbWithSearch(props) {
  const customBreadcrumb = props.breadcrumb || []
  const breadcrumb = [
    {
      name: '首页',
      href: '/'
    },
    ...customBreadcrumb
  ]
  return (
    <div className={style['breadcrumb-with-search']}>
      <div className={style['breadcrumb']}>
        {breadcrumb.map(({name, href}, index) => (
          <span className={style['breadcrumb__item']} key={index}>
            {href ? <a href={href}>{name}</a> : name}
          </span>
        ))}
      </div>
      <Search {...props.searchProps} />
    </div>
  )
}

export default BreadcrumbWithSearch
