import React from 'react'
import 'braft-editor/dist/output.css'
import style from './style.module.scss'
// 后台使用Braft Editor 按照文档方案显示 能够尽量做到输出内容的展示效果接近编辑器编辑时的效果
// 文档：https://www.yuque.com/braft-editor/be/lzwpnr#b79b9e83
function EditorShow(props) {
  const {title_type, content_type, html, plain_text, link} = props
  let content = ''
  if (title_type === 'LINK') {
    const href = link || ''
    content = `<a target="_blank" href="${href}">${href}</a>`
  } else if (content_type === 'PLAINTEXT') {
    content = `<div class="plain-text">${plain_text || ''}</div>`
  } else {
    content = html || ''
  }

  return (
    <div className={`braft-output-content ${style['custom']}`} dangerouslySetInnerHTML={{__html: content}}/>
  )
}

export default EditorShow
