import React, {useCallback, useState} from 'react'
import {withRouter} from 'react-router'
import {useHistory} from "react-router-dom";
import {Input} from 'antd'
import style from './style.module.scss'
import {INFO_TYPE, BOOK_TYPE, KEYWORD_MAX_LEN} from 'consts/common'

const types = [
  {
    value: 'book',
    label: '图书搜索',
  },
  {
    value: 'info',
    label: '站内资讯搜索',
  },
];
const bookOptions = [
  {
    label: '图书名称',
    value: BOOK_TYPE.name,
  },
  {
    label: '作者',
    value: BOOK_TYPE.author,
  },
];
const newsOptions = [
  {
    label: '资讯',
    value: INFO_TYPE.whole,
  },
  {
    label: '公告',
    value: INFO_TYPE.notice,
  },
  {
    label: '动态',
    value: INFO_TYPE.dynamic,
  },
  {
    label: '活动',
    value: INFO_TYPE.forecast,
  },
];

// 获取下拉选项
const getOptions = (type) => {
  return type === types[0].value ? bookOptions : newsOptions
};


/**
 *
 * @param props.onSearch
 * @returns {JSX.Element}
 * @constructor
 */
function SearchIndex({onSearch}) {
  const history = useHistory();

  // 搜索类型：图书搜索、资讯搜索
  const [type, setType] = useState(types[0].value);
  const handleTypeChange = useCallback((event) => {
    const type = event.target.dataset.value;
    setType(type);
    setSelected(getOptions(type)[0].value);
  }, []);

  // 搜索子类型，通过下拉切换
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(getOptions(type)[0].value);
  const handleClickCurrent = useCallback(() => {
    setActive(!active);
  }, [active]);
  const handleSelectChange = useCallback((event) => {
    setSelected(event.target.dataset.value);
    setActive(!active);
  }, [active]);
  const handleLeaveSelect = useCallback(() => {
    setActive(false);
  }, []);

  const handleSearch = useCallback((value) => {
    if (typeof onSearch === 'function') {
      onSearch(value);
      return;
    }

    history.push({
      pathname: `/searchResult/${type}`,
      search: `keyword=${encodeURIComponent(value)}&option=${selected}`,
    })
  }, [type, selected, onSearch, history]);

  return (
    <div className={style['search-index']}>
      <div className={style['title']}>
        <h1><span>敬学广惠</span><span>有教无类</span></h1>
        <div className={style['sub-title']}>LIBRARY OF XI'AN OPEN UNIVERSITY</div>
      </div>
      <div className={style['search-index__body']}>
        <div className={style['radio']}>
          {types.map(({label, value}) => {
            let className = style['radio__item']
            if (type === value) {
              className = `${style['radio__item']} ${style['radio__item--active']}`
            }
            return <span className={className} key={value} data-value={value} onClick={handleTypeChange}>{label}</span>
          })}
        </div>
        <div className={style['search__bar']}>
          <div className={`${style['select']} ${active ? style['select--active'] : ''}`}>
            <div onMouseLeave={handleLeaveSelect}>
              <div className={style['select__current']} onClick={handleClickCurrent}>
                {getOptions(type).find(({value})=> value === selected).label}
              </div>
              <div className={style['select__options']}>
                {getOptions(type).map(({value, label}) => {
                  const className = `${style['select__option']} ${value === selected ? style['select__option--active']: ''}`
                  return (
                    <div onClick={handleSelectChange} className={className} key={value} data-value={value} title={label}>{label}</div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={style['search__input']}>
            <Input.Search
              maxLength={KEYWORD_MAX_LEN}
              // value={keyword}
              // onChange={handleKeywordsChange}
              placeholder="请输入搜索内容"
              onSearch={handleSearch}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SearchIndex)
