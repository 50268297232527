import React, {useCallback, useEffect, useState} from 'react'
import {withRouter} from 'react-router'
import { BASE_URL } from 'api/config'
import style from './style.module.scss'
import {getBookRecommend} from 'api/request';
import {Image} from "antd";
import {placeholder} from "consts/imgConst";
import Empty from "components/Empty";

function RecommendationList(props) {
  const [data, setData] = useState(null);
  useEffect(() => {
    getBookRecommend(8)
      .then((res) => {
        setData(res?.data?.data_list || [])
      })
      .catch(() => {
        setData([])
      })
  }, []);

  const handleClick = useCallback((id) => {
    props.history.push(`/readingActivity/Recommendation/${id}`)
  }, [props.history])

  return (
    <div className={style['book-list']}>
      {!!data && (
        !data.length
          ? <Empty icon={'list'} tip={'暂无内容'} minHeight={500} />
          : (
            data.map((item) => {
              return (
                <div className={style['book-item-wrapper']} key={item.id}>
                  <div className={style['book-item']} onClick={() => handleClick(item.id)}>
                    <div className={style['book-cover']}>
                      <Image preview={false} src={`${BASE_URL}${item.cover_image}`} fallback={placeholder} />
                    </div>
                    <div className={style['book-title']} title={item.title}>
                      {item.title}
                    </div>
                    <div className={style['book-author']}>
                      作者：{item.author}
                    </div>
                  </div>
                </div>
              )
            })
          )
      )}
    </div>
  )
}

export default withRouter(RecommendationList)
