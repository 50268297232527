import React, {useCallback} from 'react'
import {withRouter} from 'react-router'
import {useHistory} from 'react-router-dom'
import Button from '../../../../../../components/Button'
import style from './index.module.scss'
import moment from 'moment'
import { BASE_URL } from '../../../../../../api/config'
import {Image} from "antd";
import {placeholder} from "../../../../../../consts/imgConst";
import Empty from "components/Empty";

function Dynamic(props) {
  const history = useHistory();
  const { data } = props

  const handleMore = useCallback(() => {
    history.push('/readingActivity/Trends')
  }, [history])

  const handleClick = useCallback((id) => {
    history.push(`/readingActivity/Trends/${id}`)
  }, [history])

  return (
    <div className={style['dynamic-container']} style={props.style}>
      <div className={style['dynamic-header']}>
        {!!data && (
          !data.length
            ? <Empty icon={'list'} tip={'暂无内容'} minHeight={300} />
            : (
              data.slice(0, 4).map((banner) => {
                return (
                  <div className={style['dynamic-item']} key={banner.id} onClick={() => {handleClick(banner.id)}}>
                    <div className={style['dynamic-new']}>
                      <div className={style['dynamic-inner']}>
                        <Image preview={false} src={`${BASE_URL}${banner.cover_image}`} fallback={placeholder} />
                        <div className={style['dynamic-title']} title={banner.title}>
                          {banner.title}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            )
        )}
      </div>
      <div className={style['dynamic-content']}>
        {
          data?.slice(4).map((dynamic, index) => {
            return (
            <div
              key={dynamic.id}
              className={style['dynamic-notice-wrapper']}
              onClick={() => {handleClick(dynamic.id)}}
            >
              <div className={style['dynamic-notice-inner']}>
                  <div className={style['dynamic-notice-content']} title={dynamic.title}>
                    {dynamic.title}
                  </div>
                  <div className={style['dynamic-notice-time']}>
                    {moment(dynamic.publish_time).format('YYYY-MM-DD')}
                  </div>
              </div>
            </div>
            )
          })
        }
      </div>
      <div className={style['dynamic-footer']}>
        <Button onClick={handleMore}>查看更多</Button>
      </div>
    </div>
  )
}

export default withRouter(Dynamic)
