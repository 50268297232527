import React, {useCallback} from 'react'
import {withRouter} from 'react-router'
import style from './index.module.scss'
import Button from 'components/Button'
import InfoList from 'components/InfoList'

function Notice(props) {
  const { data } = props
  const handleMore = useCallback(() => {
    props.history.push('/readingActivity/Activity')
  }, [props.history])

  const handleClick = useCallback((id) => {
    props.history.push(`/readingActivity/Activity/${id}`)
  }, [props.history])

  return (
    <div className={style['notice-wrapper']} style={props.style}>
      <div className={style['notice-container']}>
        <InfoList data={data} onClick={handleClick} />
      </div>
      <div className={style['notice-footer']}>
        <Button onClick={handleMore}>查看更多</Button>
      </div>
    </div>
  )
}

export default withRouter(Notice)
