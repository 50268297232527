import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import BreadcrumbWithSearch from '../../components/BreadcrumbWithSearch'
import {getContentData, increaseCount} from '../../api/request'

import {useRouteMatch} from 'react-router-dom'
import BaseDetail from './components/Detail'
import {BREAM_DICT} from './const'

function Detail() {
  const {params: {id, type}} = useRouteMatch()
  const [data, setData] = useState()
  const [count, setCount] = useState(0)
  useEffect(() => {
    getContentData(id).then((res) => {
      const {data} = res
      const {title = '', publish_time = 0, title_type = 'CONTENT', content_type = 'HTML', html = '', plain_text = '', link} = data || {}
      setData({title, publish_time, title_type, content_type, html, plain_text, link})
    })
    increaseCount(id).then((res) => {
      const {data} = res
      const {count = 0} = data || {}
      setCount(count)
    })
  }, [id])
  return (
    <div className={style.container}>
      <BreadcrumbWithSearch breadcrumb={BREAM_DICT[type]}/>
      <BaseDetail data={data} count={count}/>
    </div>
  )
}

export default Detail
