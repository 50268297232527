import React from 'react'
import style from './style.module.scss'
import {ENTRANCE_RULES_ID} from 'components/LeftMenu/const'
import useHtmlContent from 'hook/useHtmlContent'
import {Skeleton} from 'antd'
import EditorShow from "components/EditorShow";

function EntranceRules() {
  const [data, isLoading] = useHtmlContent(ENTRANCE_RULES_ID)
  const {title_type, content_type, html, plain_text, link} = data || {}
  return (
    <div className={style.container}>
      {!isLoading
        ? <EditorShow {...{title_type, content_type, html, plain_text, link}} />
        : <><Skeleton active/><Skeleton active/><Skeleton active/><Skeleton active/></>}
    </div>
  )
}

export default EntranceRules
