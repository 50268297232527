import React, {useEffect, useState} from 'react'
import Title from 'components/Title';
import style from './index.module.scss'
import UIBtn from '../UIBtn'
import {ENTRANCE_RULES_ID} from 'components/LeftMenu/const'
import {useHistory} from 'react-router-dom'
import {getQuest} from 'api/request'
import useHtmlContent from 'hook/useHtmlContent'
import EditorShow from 'components/EditorShow'

function ReaderGuide(props, ref) {
  const history = useHistory()
  const [{title_type, content_type, html, plain_text, link}, isLoading] = useHtmlContent(ENTRANCE_RULES_ID)

  const [questList, setList] = useState([])
  useEffect(() => {
    getQuest().then(
      (res) => {
        const {data: {data_list = []} = {}} = res
        setList(data_list)
      }
    )
  }, [])

  return (
    <div className={style.container}>
      <Title mainText={'读者指南'} tipText={'READER\'S GUIDE'} size={'small'} />
      <div className={style.main}>
        <div className={style.left}>
          <div className={style.title}>入馆须知</div>
          <div className={style.content} ref={ref}>
            {!isLoading && <EditorShow {...{title_type, content_type, html, plain_text, link}} />}
          </div>
          <div className={style.detail}>
            <UIBtn onClick={() => {
              history.push('/readerGuide/EntranceRules')
            }}>查看详情
            </UIBtn>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.title}>读者咨询</div>
          <div className={style.content}>
            {questList.map(item => {
              const {title, id} = item
              return <div key={id} className={style.questItem} onClick={() => {
                history.push(`/readerGuide/ReadersConsulting/${id}`)
              }}>
                <div className={style.quest}>
                  <div className={style.label}>{title}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.forwardRef(ReaderGuide)
