import React from 'react'
import style from './index.module.scss'
import EditorShow from '../../../components/EditorShow'
import moment from 'moment'
import {Skeleton} from 'antd'

function Detail(props) {
  const {data, count} = props
  const {title, publish_time, title_type, content_type, html, plain_text, link} = data || {}
  return (
    <div className={style.detail}>
      {props.data !== undefined ? <>
        <div className={style.title}>{title}</div>
        <div className={style.info}><span
          style={{marginRight: 56}}>发布时间：{moment(publish_time).format('YYYY-MM-DD')}</span><span>查阅人数：{count}</span>
        </div>
        <div>
          <EditorShow title_type={title_type} content_type={content_type} html={html} plain_text={plain_text} link={link} />
        </div>
      </> : <><Skeleton active/><Skeleton active/><Skeleton active/><Skeleton active/></>}
    </div>
  )
}

export default Detail
