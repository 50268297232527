import React, {useEffect, useRef, useState} from 'react'
import style from './style.module.scss'
import Title from 'components/Title';
import useSize from '../../useSize'

const MARGIN = 80
const MAX_WIDTH = 376
const MAX_HEIGHT = 256
const RATIO = MAX_WIDTH / MAX_HEIGHT

const books = [
  [
    {
      title: '超星学术<span>文献数据库<span>',
      link: 'http://qikan.chaoxing.com'
    },
    {
      title: '超星学术<span>视频</span>',
      link: 'https://ssvideo.superlib.com'
    },
    {
      title: '百链云<span>图书馆</span>',
      link: 'https://www.blyun.com'
    },
  ],
  [
    {
      title: '中国知网',
      link: 'https://www.cnki.net'
    },
    {
      title: '超星数字<span>图书数据库</span>',
      link: 'http://www.sslibrary.com'
    },
    {
      title: '读秀数图<span>资源检索</span>',
      link: 'https://www.duxiu.com'
    },
  ]
];


function renderItem({title, index, link, calcWidth}) {
  return (
    <a
      className={`${style['item']} ${style['item-' + index]}`}
      key={index}
      href={link}
      target='_blank'
      rel="noreferrer"
      style={{width: calcWidth, height: calcWidth / RATIO}}
    >
      <span
        className={style['title']}
        style={{transform: `scale(${calcWidth / MAX_WIDTH})`}}
        dangerouslySetInnerHTML={{__html: title}}
      />
    </a>
  )
}

function DigitalBooks() {
  const wrapEl = useRef(null);
  const [actualHeight, setActualHeight] = useState(0);
  const {contentWidth, contentHeight} = useSize()
  const width = (contentWidth - MARGIN * 2) / 3
  useEffect(() => {
    setActualHeight(wrapEl.current.clientHeight - 120 - 104)
  }, [contentHeight])
  const height = (actualHeight - MARGIN) / 2
  const calcWidth = (width / height) / RATIO > 1 ? height * RATIO : width

  return (
    <div className={style['digital-books']} ref={wrapEl}>
      <Title mainText={'数字图书'} tipText={'DIGITAL BOOKS'} size={'small'} />
      <div className={style['list']}>
        {books.map((booksOneRow, row) => (
          <div key={row} className={style['row']}>
            {booksOneRow.map(({title, link}, index) =>
              renderItem({index: index + row * 3, title, calcWidth, link})
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DigitalBooks
