import React from 'react'
import Title from 'components/Title';
import style from './index.module.scss'
import EditorShow from 'components/EditorShow'
import {Skeleton} from 'antd'
import {LIBRARY_INTRODUCTION_ID} from 'components/LeftMenu/const'
import useHtmlContent from 'hook/useHtmlContent'

function LibraryOverviewCard(props, ref) {
  const [data, isLoading] = useHtmlContent(LIBRARY_INTRODUCTION_ID)
  const {title_type, content_type, html, plain_text, link} = data || {}
  return (
    <div className={style.container}>
      <Title mainText={'图书馆概况'} tipText={'LIBRARY OVERVIEW'} mainStyle={{color: '#fff'}}
             tipStyle={{color: '#fff'}}/>
      <div className={style.editContainer} ref={ref}>
        <div className={style.scrollView}>
          {!isLoading ? <EditorShow {...{title_type, content_type, html, plain_text, link}} /> : <Skeleton/>}
        </div>
      </div>
    </div>
  )
}

export default React.forwardRef(LibraryOverviewCard)
