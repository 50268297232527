import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Title from 'components/Title';
import style from './index.module.scss'
import UIBtn from '../UIBtn'
import useSize from '../../useSize'
import {getNotice} from '../../../../api/request'
import InfoList from 'components/InfoList';

function NoticeCard() {
  const history = useHistory()
  const {contentHeight} = useSize()
  const [rowCount, setRowCount] = useState(10)
  const [data, setData] = useState(null)
  useEffect(() => {
    getNotice().then(
      (res) => {
        const {data: {data_list = []} = {}} = res
        setData(data_list)
      }
    )
  }, [])
  useEffect(() => {
    setRowCount(Math.floor((contentHeight - 240) / 56))
  }, [contentHeight])
  const handleMore = useCallback(() => {
    history.push(`/notice`)
  }, [history])
  const showData = data?.slice(0, rowCount * 2)
  return (
    <div className={style.container}>
      <Title mainText={'通知公告'} tipText={'NOTICE ANNOUNCEMENT'} size={'small'}/>
      <div className={style.noticeList}>
        <InfoList data={showData} />
      </div>
      <div className={style.moreBtnContainer}>
        <UIBtn onClick={handleMore}>更多</UIBtn>
      </div>
    </div>
  )
}

export default NoticeCard
