import React from 'react'
import styles from './index.module.scss'
import Image from '../Image'
import defaultBg from './images/img-back.png'
import {BASE_URL} from '../../api/config'
import moment from 'moment'
import Ellipsis from '../Ellipsis'
import {removeHTMLTag} from '../../utils/removeHTMLTag'

function ListItem({ data, showCover = true, showBrief = true, showDate = true, onClick = () => {}}) {
  const {id, cover_image, title, html, publish_time} = data
  const text = removeHTMLTag(html)
  return (
    <div key={id} className={styles.listItem} onClick={onClick}>
      {showCover && (
        <div className={styles.coverWrap}>
          <Image
            src={cover_image ? `${BASE_URL}${cover_image}` : ''}
            defaultSrc={defaultBg}
          />
        </div>
      )}
      <div className={styles.textInfo}>
        <div className={styles.title} title={title}>
          {title}
        </div>
        {showBrief && (
          <div className={styles.detail} style={showCover ? null : {height: 'auto'}}>
            <Ellipsis line={3} lineHeight={22}> {text}</Ellipsis>
          </div>
        )}
        {showDate && (
          <div className={styles.date}>
            发布时间：{moment(publish_time).format('YYYY-MM-DD')}
          </div>
        )}
      </div>
    </div>
  )
}

export default ListItem
