import React from 'react'
import style from './index.module.scss'

function Empty({icon = 'search', tip = '暂无搜索结果', minHeight = 0}) {
  return (
    <div className={style['empty']} style={{minHeight}}>
      <div className={`${style.container} ${style[icon]}`}>
        <div className={style['tip']}>{tip}</div>
      </div>
    </div>
  )
}

export default Empty
