import React, {useCallback} from 'react'
import style from './index.module.scss'

function Button({onClick, children}) {
  const handleClick = useCallback(() => {
    typeof onClick === 'function' && onClick()
  }, [onClick])
  return (
    <div className={style['button']} onClick={handleClick}>
      {children}
    </div>
  )
}

export default Button
