import React from 'react'
import style from './style.module.scss'
import LeftMenu from '../../components/LeftMenu'
import QuestList from './components/QuestList'
import {
  ENTRANCE_RULES,
  READER_GUIDE_MENUS,
  READERS_CONSULTING
} from '../../components/LeftMenu/const'
import {withRouter} from 'react-router'
import EntranceRules from './components/EntranceRules'
import BreadcrumbWithSearch from '../../components/BreadcrumbWithSearch'

function ReaderGuide(props) {
  const {match: {params: {type = ''}}, history} = props
  return (
    <div className={style.container} style={{marginBottom: type === READERS_CONSULTING ? -16 : 0}}>
      <BreadcrumbWithSearch
        breadcrumb={[
          {name: '读者指南', href: '/readerGuide/EntranceRules'},
          {name: READER_GUIDE_MENUS.find(({key}) => type === key).name}
        ]}
      />
      <LeftMenu activeKey={type} menus={
        READER_GUIDE_MENUS.map((menu) => {
          return {
            ...menu,
            handleClick: () => {
              history.push(`/readerGuide/${menu.key}`)
            }
          }
        })}/>
      {type === ENTRANCE_RULES && <EntranceRules/>}
      {type === READERS_CONSULTING && <QuestList/>}
    </div>
  )
}

export default withRouter(ReaderGuide)
