import React from 'react'
import style from './index.module.scss'

function UIBtn(props) {
  return (
    <div  {...props} className={style.uiBtn}/>
  )
}

export default UIBtn
