import React from 'react'

export default class Ellipsis extends React.Component {
  static defaultProps = {
    line: 2,
    ellipsis: '...'
  }

  constructor(props) {
    super(props)

    this.text = ''
    this.setLineClamp = this.setLineClamp.bind(this)
    this.setLineNormal = this.setLineNormal.bind(this)
    this.clipText = this.clipText.bind(this)
    this.init = this.init.bind(this)
    this.ellip = null
    this.tempBox = null
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    if (this.props.line !== prevProps.line) {
      this.init()
    }
  }

  init() {
    if ('webkitLineClamp' in document.documentElement.style) {
      this.setLineClamp()
      this.removeTpl()
    } else {
      if (this.props.lineHeight) {
        this.setLineClamp()
        this.removeTpl()
      } else {
        this.setLineNormal()
        this.clipText()
      }
    }
  }

  removeTpl() {
    try {
      this.ellip.removeChild(this.tempBox)
    } catch (err) {
    }
  }

  setLineNormal() {
    Object.assign(this.ellip.style, {
      'word-break': 'break-all'
    })
  }

  setLineClamp() {
    Object.assign(this.ellip.style, {
      'overflow': 'hidden',
      'display': '-webkit-box',
      'webkitBoxOrient': 'vertical',
      'word-break': 'break-all',
      'webkitLineClamp': this.props.line,
      'word-wrap': 'break-word',
      ...(this.props.lineHeight ? {'max-height': `${this.props.line * this.props.lineHeight}px`} : {})
    })
  }

  clipText() {
    let {
      line, ellipsis, end = () => {
      }
    } = this.props
    let ellip = this.ellip

    if (!this.h) {
      let getHeight = this.tempBox
      this.h = getHeight.offsetHeight
      this.removeTpl()
    }

    let getCountHeight = () => {
      return parseFloat(getComputedStyle(ellip)['height'], 10)
    }

    let init = true

    if (!this.text) {
      this.text = ellip.textContent
    } else {
      ellip.innerHTML = this.text
    }

    let text = this.text
    let clip = () => {
      let len = 0
      while (Math.floor(getCountHeight() / this.h) > line) {
        len += 1

        text = text.slice(0, -1)
        ellip.innerHTML = text

        if (!init) {
          ellip.innerHTML += ellipsis
        }
      }

      return len
    }

    if (0 < clip()) {
      ellip.innerHTML += ellipsis
      init = false
      clip()
    }

    end()
  }

  render() {
    let {children, className = ''} = this.props

    return (
      <div className={className}>
        <div ref={ellip => this.ellip = ellip}>
          {children}
          <div ref={tempBox => this.tempBox = tempBox} style={{visibility: 'hidden'}}>好</div>
        </div>
      </div>
    )
  }
}
